import React from 'react'
import { Div, P} from '../styled-components'

const CertificationsInfo = () => (
  <Div
    padding="50px 20px"
    display="flex"
    flexDirection="column"
    align_items="center"
  >
    <P
      margin="0 0 20px"
      fontSize="32px"
      fontWeight="bold"
      color="#666"
      textAlign="center"
    >
      Certifications
    </P>
    <P
      margin="0 0 20px"
      fontSize="18px"
      color="#666"
      textAlign="center"
    >
      PREMIERE ENERGY SOLUTIONS, Inc is certified by the Associated Air Balance Council established in 1965.
    </P>
    <P
      maxWidth="746px"
      margin="0 0 20px"
      fontSize="18px"
      color="#666"
      textAlign="center"
    >
      Independence is the cornerstone of AABC. Members are strictly prohibited from having any affiliations with mechanical contractors, design engineers, or equipment manufacturers.
    </P>
    <P
      maxWidth="744px"
      margin="0 0 30px"
      fontSize="18px"
      color="#666"
      textAlign="center"
    >
      This policy prevents any potential conflicts of interest and ensures that all members provide engineers and building owners with professional, unbiased test and balance services.
    </P>
    <Div
      width="280px"
      height="2px"
      background_color="#69B02A"
    />
    <P
      margin="30px 0 20px"
      fontSize="32px"
      fontWeight="bold"
      color="#666"
      textAlign="center"
    >
      National Performance Guaranty
    </P>
    <P
      maxWidth="755px"
      margin="0"
      fontSize="18px"
      color="#666"
      textAlign="center"
    >
      All projects completed by AABC members are covered by the National Performance Guaranty, providing assurance that your project will be completed in accordance with the AABC National Standards and the project specifications. Click here for more information.
    </P>
  </Div>
)

export default CertificationsInfo