import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GeneralBanner from '../components/generalBanner'
import CertificationsInfo from '../components/certifications/certificationsInfo'
import Certificates from '../components/certifications/certificates'
import Certification from '../components/certification'
import Band from "../components/band"

const Certifications = () => (
  <Layout>
    <SEO title="Certifications" />
    <GeneralBanner
      title="CERTIFICATIONS"
    />
    <CertificationsInfo/>
    <Certificates />
    <Certification
      background="#fff"
    />
    <Band
      bandBackground="#23227B"
    />
  </Layout>
)

export default Certifications