import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Div, Button } from "../styled-components"
import scrollTo from 'gatsby-plugin-smoothscroll'

const Certificates = () => {
  const data = useStaticQuery(graphql`
    query {
      certificate01: file(relativePath: { eq: "c1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      certificate02: file(relativePath: { eq: "c2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      certificate03: file(relativePath: { eq: "c3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      certificate04: file(relativePath: { eq: "c4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      certificate05: file(relativePath: { eq: "c5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      certificate06: file(relativePath: { eq: "c6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      certificate07: file(relativePath: { eq: "c7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      certificate08: file(relativePath: { eq: "c8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  const dataArray = Object.values(data)
  const [certificate, setCertificate] = useState(dataArray[0].childImageSharp.fluid)
  
  const handleSetCertificate = (event, index) => {
    event.preventDefault()
    setCertificate(dataArray[index].childImageSharp.fluid)
    scrollTo('#certificateView')
  }
  
  return (
    <Div
      padding="40px 10px"
      width="100%"
      display="flex"
      flexDirection="column"
      align_items="center"
      background_color="#f2f2f2"
    >
      <Div
        display="flex"
        flexDirection960="column"
      >
        <Button
          padding="0"
          width="200px"
          height="240px"
          width960="260px"
          height960="400px"
          onClick={(event) => handleSetCertificate(event, 0)}
        >
          <Img
            fluid={data.certificate01.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
        <Button
          padding="0"
          margin="0 20px"
          margin960="20px 0"
          width="200px"
          height="240px"
          width960="260px"
          height960="400px"
          onClick={(event) => handleSetCertificate(event, 1)}
        >
          <Img
            fluid={data.certificate02.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
        <Button
          padding="0"
          width="200px"
          height="240px"
          width960="260px"
          height960="400px"
          onClick={(event) => handleSetCertificate(event, 2)}
        >
          <Img
            fluid={data.certificate03.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
      </Div>
      <Div
        margin="20px 0"
        display="flex"
        flexDirection960="column"
      >
        <Button
          padding="0"
          margin="0 20px 0 0"
          margin960="0 0 20px 0"
          width="230px"
          height="165px"
          width960="260px"
          height960="210px"
          onClick={(event) => handleSetCertificate(event, 3)}
        >
          <Img
            fluid={data.certificate04.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
        <Button
          padding="0"
          width="230px"
          height="165px"
          width960="260px"
          height960="210px"
          onClick={(event) => handleSetCertificate(event, 4)}
        >
          <Img
            fluid={data.certificate05.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
        <Button
          padding="0"
          width="230px"
          height="165px"
          width960="260px"
          height960="210px"
          onClick={(event) => handleSetCertificate(event, 5)}
        >
          <Img
            fluid={data.certificate06.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
      </Div>
      <Div
        margin="20px 0"
        display="flex"
        flexDirection960="column"
      >
        <Button
          padding="0"
          margin="0 20px 0 0"
          margin960="0 0 20px 0"
          width="230px"
          height="165px"
          width960="260px"
          height960="210px"
          onClick={(event) => handleSetCertificate(event, 6)}
        >
          <Img
            fluid={data.certificate07.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
        <Button
          padding="0"
          width="230px"
          height="165px"
          width960="260px"
          height960="210px"
          onClick={(event) => handleSetCertificate(event, 7)}
        >
          <Img
            fluid={data.certificate08.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
      </Div>
      <Div
        width="800px"
        display870="none"
        id="certificateView"
      >
        <Img
          fluid={certificate}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Div>
    </Div>
  )
}

export default Certificates